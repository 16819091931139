<template>
  <v-card>
    <v-card-title>
      Excalibur User Information
      <v-spacer></v-spacer>
      <v-btn @click="$router.push({ name: 'ExcaliburUsers' })" class="primary"
        >Back</v-btn
      >
    </v-card-title>
    <v-card-text>
      <ul>
        <li>
          For cases where the delivery image is not showing , please use
          <code>Mozilla Firefox</code> or any other <code>web browser</code> except for
          <code>Google Chrome</code> !
        </li>
      </ul>
    </v-card-text>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>Profile</v-expansion-panel-header>
        <v-expansion-panel-content>
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th style="width: 30%" scope="col">Title</th>
                <th scope="col">Answer</th>
              </tr>
            </thead>
            <tbody v-for="(value, key, idx) in user" :key="'p_' + key + idx">
              <tr v-if="!['__v', 'password'].includes(key)">
                <td class="bold">{{ key }}:</td>
                <td>{{ value }}</td>
              </tr>
            </tbody>
          </table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-expansion-panels>
      <v-expansion-panel @click="loadDeliveryStatus()">
        <v-expansion-panel-header>Delivery Status</v-expansion-panel-header>
        <v-expansion-panel-content>
          <!-- <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th style="width: 30%" scope="col">Title</th>
                <th scope="col">Answer</th>
              </tr>
            </thead>
            <tbody
              v-for="(value, key, idx) in deliveryStatus"
              :key="'p_' + key + idx"
            >
              <tr v-if="value.type == undefined">
                <td class="bold">{{ key }}:</td>
                <td>{{ value }}</td>
              </tr>

              <tr v-if="value.type == 'image'">
                <td class="bold">{{ key }}:</td>
                <td>
                  <v-img
                    class="white--text"
                    height="100%"
                    width="400px"
                    :src="value.image"
                  />
                </td>
              </tr>
            </tbody>
          </table> -->
          <v-data-table 
            :headers="headers" 
            :items="deliveryStatus" 
            hide-default-footer
            disable-pagination>
              <template v-slot:[`item.deliveryImage`]="{ item }">
                <div class="d-flex justify-center ma-4">
                    <v-img 
                      height="300px" 
                      width="300px" 
                      :src="item.deliveryImage"
                      @click="zoom(item.deliveryImage)" 
                    />
                    <v-dialog 
                      v-model="selectedImage" 
                      max-width="750px">
                        <v-img 
                          height="750px" 
                          width="750px" 
                          :src="selectedUrl" 
                        />
                    </v-dialog>
                  </div>
              </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>


<script>
import RestResource from "@/services/dataServiceExcalibur.js";
const service = new RestResource();

export default {
  data() {
    return {
       headers: [
                { text: "Status", value: "deliveryStatus", align: 'center' },
                { text: "Product Image", value: "deliveryImage", align: 'center' },
            ],
      user: {},
      deliveryStatus: [],
      header: null,
      selectedImage: false,
      selectedUrl: "",
    };
  },

  mounted() {
    this.loadUsers();
    setTimeout(function () {
      window.dispatchEvent(new Event("resize"));
    }, 2500);
  },

  methods: {
    zoom(url) {
        this.selectedImage = true;
        this.selectedUrl = url;
    },

    async loadUsers() {
      this.$setLoader();

      await service
        .getExcaliburUser({ userId: this.$route.params.id })
        .then((res) => {
          this.user = res.data;
          this.$disableLoader();
        });
    },

    async loadDeliveryStatus() {
      this.$setLoader();

      await service
        .getDeliveryStatus({
          trackingNumber: this.user.trackingNumber,
          phone: this.user.phone,
        })
        .then((res) => {
          this.deliveryStatus = res.data;
          this.$disableLoader();
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "~bootstrap/scss/bootstrap.scss";

.bold {
  font-weight: bold;
  max-width: 40%;
}
</style>